/* eslint-disable react/destructuring-assignment */
// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ReactPageScroller from '../lib/index';

// Instruments
import '../static/styles/index.scss';
import Layout from '../layouts/en';
import SEO from '../components/seo';
import MainSection from '../pages-sections/what-we-do-sections/main-section/main-section';
import Pagination from '../components/pagination/pagination';
import LineIndicator from '../components/line-indicator/line-indicator';
import MarketsSection from '../pages-sections/what-we-do-sections/markets-section/markets-section';
import EquitySection from '../pages-sections/what-we-do-sections/equity-section/equity-section';
import EstateSection from '../pages-sections/what-we-do-sections/estate-section/estate-section';
import { returnNum } from '../helpers/helpers';
import useGoToPage from '../hooks/useGoToPage';

const IndexPage = ({ location, data }) => {
  const locationLink = location.hash.length ? returnNum(location.hash) : 1;
  const [currentPage, setCurrentPage] = useState(locationLink);
  const [lineHeight, setLineHeight] = useState(0);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [windowSize, setWindowSize] = useState(1024);
  const [showSections, setShowSections] = useState(false);
  const isMobile = windowSize <= 1024;
  const wpData = data && data.allWordpressPage.edges.filter((item) => item.node.title === 'What we do')[0].node.acf;
  const MENU_ITEMS = [
    wpData.section_title_1,
    wpData.section_title_2,
    wpData.section_title_3,
  ];
  const [ref, goToPage] = useGoToPage(location, isMobile, MENU_ITEMS, currentPage);

  const onChangeSection = ({ index, name }) => {
    goToPage(index, name);
  };

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };

  const handleWindowScroll = () => {
    const scrollBlocker = window.pageYOffset > 0 || window.pageYOffset < 0;

    setCanScrollUp(scrollBlocker);
    return true;
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    if (!isMobile) {
      document.addEventListener('scroll', handleWindowScroll, { passive: false });
      setShowSections(false);
    } else {
      setShowSections(true);
    }
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    const activePaginationButton = document.querySelector('button[data-activeitem="active"]');
    const topPosition = activePaginationButton
      ? activePaginationButton.getBoundingClientRect().top + 20 : 486;
    setLineHeight(topPosition);
    setTimeout(() => {
      if (showSections !== (currentPage === MENU_ITEMS.length + 1)) {
        setShowSections(currentPage === MENU_ITEMS.length + 1);
      }
    }, 1000); // time for render sections that are out of pageScroller
  }, [currentPage, isMobile, windowSize]);

  if (windowSize === 0) {
    return (<></>);
  }
  const renderSections = () => (
    [
      <MainSection
        activeSection={currentPage}
        isMobile={isMobile}
        title={wpData.section_1_name}
        data={
              {
                title: wpData.section_1_title,
                titleBold: wpData.section_1_title_bold,
                image: wpData.section_1_background_image,
              }
            }
      />,
      <MarketsSection
        activeSection={currentPage}
        isMobile={isMobile}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_1}
          />
        )}
        sectionName={wpData.section_title_1}
        data={{
          title: wpData.section_2_title,
          text1: wpData.section_2_p_1,
          text2: wpData.section_2_p_2,
          text3: wpData.section_2_p_3,
          link: wpData.section_2_link,
          image: wpData.section_2_background_image,
        }}
      />,
      <EquitySection
        activeSection={currentPage}
        isMobile={isMobile}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_2}
          />
      )}
        sectionName={wpData.section_title_2}
        data={{
          title: wpData.section_3_title,
          text1: wpData.section_3_p_1,
          text2: wpData.section_3_p_2,
          text3: wpData.section_3_p_3,
          link: wpData.section_3_link,
          image: wpData.section_3_background_image,
        }}
      />,
      <EstateSection
        activeSection={currentPage}
        isMobile={isMobile}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_3}
          />
      )}
        sectionName={wpData.section_title_3}
        data={{
          title: wpData.section_4_title,
          text1: wpData.section_4_p_1,
          text2: wpData.section_4_p_2,
          text3: wpData.section_4_p_3,
          link: wpData.section_4_link,
          image: wpData.section_4_background_image,
        }}
      />,
    ]
  );
  return (
    <>
      <Layout
        currentSection={currentPage}
        sectionsCount={MENU_ITEMS.length + 1}
        location={location}
        isMobile={isMobile}
        changeHandler={onChangeSection}
        showFooter={showSections}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <SEO title="What we do" />
        <div data-currentPage={`currentPage_${currentPage}`}>
          {windowSize > 1024 ? (
            <ReactPageScroller
              ref={ref}
              pageOnChange={(num) => setCurrentPage(num)}
              customPageNumber={currentPage}
              transitionTimingFunction="cubic-bezier(.435,.01,.135,1)"
              blockScrollUp={canScrollUp}
              scrollBlocker={showSections}
              pagination={(
                <Pagination
                  canScrollUp={canScrollUp}
                  menuItems={MENU_ITEMS}
                  changeHandler={onChangeSection}
                  activeItem={currentPage}
                />
            )}
            >
              {renderSections().map((section) => section)}
            </ReactPageScroller>
          ) : (
            <div ref={ref}>
              {renderSections().map((section) => section)}
            </div>
          )}
          <LineIndicator
            height={lineHeight}
            activeItem={currentPage}
          />
        </div>
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
IndexPage.defaultProps = {
  data: null,
};

export default IndexPage;
export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {

            section_title_1
            section_title_2
            section_title_3

            section_1_title
            section_1_title_bold
            section_1_name
            section_1_background_image {
              alt
              url
            }

            section_2_title
            section_2_p_1
            section_2_p_2
            section_2_p_3
            section_2_link
            section_2_background_image {
              alt
              url
            }

            section_3_title
            section_3_p_1
            section_3_p_2
            section_3_p_3
            section_3_link
            section_3_background_image {
              alt
              url
            }

            section_4_title
            section_4_p_1
            section_4_p_2
            section_4_p_3
            section_4_link
            section_4_background_image {
              alt
              url
            }

            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
          title
          slug
        }
      }
    }
  }`;
